export const homeContent = [
    {
        title: "Monitoring & evaluation data portals",
        description: "Make use of an integrated M&E data portal that is tailored to your data needs.",
        icon_position: "left",
        icon: "/icons/ic-monitoring-evaluation.svg",
        link: "/data-portal",
        transform: false,
    },
    {
        title: "Remote data collection",
        description: "Save resources and ensure rapid response by capturing your data remotely.",
        icon_position: "right",
        icon: "/icons/ic-remote-data-collection.svg",
        link: "#",
        transform: false,
    },
    {
        title: "Custom reports",
        description: "Combine quantitative data in flexible, graphic-designed reports.",
        icon_position: "left",
        icon: "/icons/ic-custom-reports.svg",
        link: "#",
        transform: "rotate(-10deg)",
    },
];