export const dataPortalContent = [
    {
        title: "Beyond Chocolate",
        description: "Beyond Chocolate is an initiative to increase the sustainability of the Belgian chocolate industry. Their custom M&E portal is used to collect and report on data by various member organisations in Germany, Belgium, Switzerland and the Netherlands. Given the complex nature of the organisational set up, this portal was built with a strong focus on role and member-based data access.",
        images: [
            "bc-1.png",
            "bc-2.png",
            "bc-3.png",
        ]
    },
    {
        title: "Covid Uganda 5W WaSH portal",
        description: "This M&E portal for UNICEF Uganda is used to monitor the various COVID19 related WASH activities performed by its partners. This portal enables partners to monitor the COVID19 response across the country, and identify gaps where interventions could be directed.",
        images: [
            "Uganda-1.png",
            "Uganda-2.png",
            "Uganda-3.png",
        ]
    },
];